.dividerContainer{
    position: relative;
    background-color: #ffd43b;
    padding: 1px;
    margin: 32px 0;
}
.dividerContainer span{
    position: absolute;
    top: -20px;
    left: 0;
    background-color: #fab005;
    padding: 8px 16px;
    color: #fff;
    font-style: italic;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}