a{
    text-decoration: none;
    color:black;
    font-size: 1rem;
    font-weight: lighter;
    display: block;
    
}
ul{
    list-style: none;
}
.avatar{
    height:50px;
    width:50px;
    border-radius: 20px;
}
.profile{
    position: relative;
}
.shape{
    display: none;
    position: absolute;
    height: 20px;
   
}
.drop-down{
    display: none;
   position: absolute;
   top:100%;
   left: -155%;
   padding: 10px;

  
}
.profile:hover .shape{
    display: block;
}
.profile:hover  .triangle{
    display: block;
}
.profile:hover .drop-down {
    display: block;
    background-color: #fff;
    border-radius: 3px;
  }
  a:hover{
    color:#fab005;
    background-color: #f1f3f5;
}
.user_profile li{
    padding: 10px 16px 10px 16px;
}