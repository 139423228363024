.notificationContainer{
    padding: 16px 8px;
}
.notificationContainer p{
    margin-bottom: 2px;
}
/* .notificationContainer button{
    background: none;
    border: 1px solid #ffd43b;
    padding: 8px 16px;
    border-radius: 8px;
    color: #fcc419;
    font-weight: 600;
} */
.notificationContainer button:hover{
    background-color: #fcc419;
    color: #fff;
}
.notificationTitle{
    background-color: red;
    color: white;
    padding: 4PX 8PX;
    border-radius: 10PX;
    margin-bottom: 10px;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.notificationSummary{
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.notificationDetail{
    font-size: 16px;
}
.notificationDetail span{
    color: #f59f00;
    font-weight: 600;
    font-style: italic;
    font-size: 16px;
}

/* CSS */
/* .notificationContainer button {
  font-size: 16px;
  font-weight: 200;
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  padding: 16px 8px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.notificationContainer button:after {
  content: "";
  background-color: #ffe54c;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.notificationContainer button:hover:after {
  top: 0px;
  left: 0px;
} */

/* @media (min-width: 768px) {
  .notificationContainer button {
    padding: 13px 50px 13px;
  }
} */

/* CSS */
.notificationContainer button {
  align-items: center;
  background-color: #f59f00;
  border: 2px solid #fff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 1px;
}

.notificationContainer button:after {
  background-color: #ffd43b;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform .2s ease-out;
  z-index: -1;
}
.notificationContainer button:disabled {
    background-color: #ccc;   
}
.notificationContainer button:disabled::after {
    background:none;   
}

.notificationContainer button:hover:after {
  transform: translate(0, 0);
}

.notificationContainer button:active {
  background-color: #e67700;
  outline: 0;
}

.notificationContainer button:hover {
    background-color: #f59f00;
  outline: 0;
}

/* @media (min-width: 768px) {
  .notificationContainer button {
    padding: 0 40px;
  }
} */