::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: #ffe8cc;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background:  #ffe8cc;
  }
  
  .scroll-box {
    overflow-y: scroll;
    height: 100%;
    /* border: 1px solid #ccc; */
  }
  .nav_back{
    overflow-y: auto;
    background-image: url('../../../assets//sideNav_background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }
  .accordion.expanded:before {
    transform: rotate(45deg);
    /* -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg); */
  }