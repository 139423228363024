.MuiPaper-elevation {
  border: none !important;
  box-shadow: none !important;
}
body {
  font-family: 'Ubuntu', sans-serif !important;
}
.price_input 
.MuiInputBase-input {
  padding: 5px !important;
}
/* .react-datepicker-wrapper {
  width: 100%;
  border: 1px solid #e8ebee;
}

.datepicker_input {
  width: 100%;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #e8ebee;
} */
.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color:lightgrey !important;
}
.MuiFormLabel-root.Mui-error{
  color: lightgray !important
}
/* Override the default styles of the DatePicker container */
.custom-datepicker {
  display: inline-block;
  font-family: sans-serif, Montserrat;
  width: 100%;
}

/* Override the styles of the DatePicker input field */
.custom-datepicker input {
  width: 100%;
  height: 55px;
  padding: 10px;
  border: 1px solid #e8ebee;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  outline: none;
}

/* Override the styles of the DatePicker input field when focused */
.custom-datepicker input:focus {
  box-shadow: 0 0 0 1px #fc9200;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.react-pdf {
  position: relative;
  /* // top: -10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 40px;
  left: 4px;
  width: 100%;
  height: 100%;
}

.imgTitle {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;

  position: absolute;

  bottom: 54px;
}

/* Override the styles of the DatePicker calendar */
.custom-datepicker .react-datepicker {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-size: 14px;
  color: #333;
}

/* Override the styles of the DatePicker selected date */
.custom-datepicker .react-datepicker__day--selected {
  background-color: #007bff;
  color: #fff;
}

/* Override the styles of the DatePicker today's date */
.custom-datepicker .react-datepicker__day--today {
  font-weight: bold;
}
a:hover {
  background-color: transparent;
}
